.spacing--bottom-36 {
  margin-bottom: 26px !important; }
  @media (max-width: 898px) {
    .spacing--bottom-36 {
      margin-bottom: 13px !important; } }

.spacing--bottom-72 {
  margin-bottom: 63px !important; }
  @media (max-width: 898px) {
    .spacing--bottom-72 {
      margin-bottom: 31px !important; } }

.spacing--bottom-124 {
  margin-bottom: 115px !important; }
  @media (max-width: 898px) {
    .spacing--bottom-124 {
      margin-bottom: 51px !important; } }

.spacing--bottom-144 {
  margin-bottom: 126px !important; }
  @media (max-width: 898px) {
    .spacing--bottom-144 {
      margin-bottom: 63px !important; } }

* {
  outline: none; }

html,
body {
  height: 100%;
  font-size: 16px; }

body {
  background-color: white;
  padding: 0 !important;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100vw; }

main {
  display: block;
  /* new */ }

.black-box.arrow-right:before {
  background: url("../images/white-arrow.svg");
  background-repeat: no-repeat; }

/* IE Fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .content.guide .sticky {
    top: 25% !important; }
  [class^='Mainstyles__HeaderButtonWrapper'] {
    -ms-transform: translateX(-700%) !important; } }

.modal-img {
  max-width: 100%;
  display: block;
  margin: auto;
  overflow: auto; }

.modal-close {
  color: white;
  font-size: 25px;
  background-color: black;
  width: 40px;
  height: 40px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: center;
  line-height: 30px;
  cursor: pointer; }

.modal-download {
  color: white;
  font-size: 25px;
  background-color: black;
  width: 40px;
  height: 40px;
  font-weight: bold;
  position: absolute;
  top: 7px;
  right: 65px;
  text-align: center;
  cursor: pointer; }
  .modal-download a {
    color: white !important; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?ywTsm632");
  src: url("../fonts/icomoon.eot?ywTsm632#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff2?ywTsm632") format("woff2"), url("../fonts/icomoon.ttf?ywsTm632") format("truetype"), url("../fonts/icomoon.woff?ywTsm632") format("woff"), url("../fonts/icomoon.svg?ywsTm632#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-updates:before {
  content: '\e912'; }

.icon-Reviews:before {
  content: '\e90d'; }

.icon-Education-Sessions:before {
  content: '\e90a'; }

.icon-Recognition--Rewards-1:before {
  content: '\e90b'; }

.icon-Performance-Dashboard:before {
  content: '\e90c'; }

.icon-Up-to-date-News:before {
  content: '\e906'; }

.icon-Support-and-resources:before {
  content: '\e907'; }

.icon-Preferred-Vendors:before {
  content: '\e910'; }

.icon-Performance-Report-1:before {
  content: '\e909'; }

.icon-management::before {
  content: '\e905';
  vertical-align: middle; }

.icon-support::before {
  content: '\e900';
  vertical-align: middle; }

.icon-Resources::before {
  content: '\e90f';
  vertical-align: middle; }

.icon-Rewards::before {
  content: '\e90e';
  vertical-align: middle; }

.icon-reviews::before {
  content: '\e902';
  vertical-align: middle; }

.icon-manual::before {
  content: '\e903';
  vertical-align: middle; }

.icon-my-performance::before {
  content: '\e904';
  vertical-align: middle; }

.icon-preferred-vendors::before {
  content: '\e908';
  vertical-align: middle; }

@font-face {
  font-family: 'Hyundai Medium Head';
  src: url("../fonts/hyundai_sans_head_officeMd.eot?ywsm632");
  src: url("../fonts/hyundai_sans_head_officeMd.eot?ywsm632#iefix") format("embedded-opentype"), url("../fonts/hyundaisansheadoffice-medium-webfont.woff2") format("woff2"), url("../fonts/hyundaisansheadoffice-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Hyundai Medium Text';
  src: url("../fonts/hyundai_sans_text_officeMdd.eot?ywsm632");
  src: url("../fonts/hyundai_sans_text_officeMdd.eot?ywsm632#iefix") format("embedded-opentype"), url("../fonts/hyundaisanstextoffice-medium-webfont.woff2") format("woff2"), url("../fonts/hyundaisanstextoffice-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Hyundai Regular Head';
  src: url("../fonts/hyundai_sans_head_officeRg.eot?ywsm632");
  src: url("../fonts/hyundai_sans_head_officeRg.eot?ywsm632#iefix") format("embedded-opentype"), url("../fonts/hyundaisansheadoffice-regular-webfont.woff2") format("woff2"), url("../fonts/hyundaisansheadoffice-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Hyundai Regular Text';
  src: url("../fonts/hyundai_sans_text_officeRg.eot?ywsm632");
  src: url("../fonts/hyundai_sans_text_officeRg.eot?ywsm632#iefix") format("embedded-opentype"), url("../fonts/hyundaisanstextoffice-regular-webfont.woff2") format("woff2"), url("../fonts/hyundaisanstextoffice-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Hyundai Bold Head';
  src: url("../fonts/hyundai_sans_head_officebold.eot?ywsm632");
  src: url("../fonts/hyundai_sans_head_officebold.eot?ywsm632#iefix") format("embedded-opentype"), url("../fonts/hyundaisansheadoffice-bold-webfont.woff2") format("woff2"), url("../fonts/hyundaisansheadoffice-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Hyundai Bold Text';
  src: url("../fonts/hyundai_sans_text_officebold.eot?ywsm632");
  src: url("../fonts/hyundai_sans_text_officebold.eot?ywsm632#iefix") format("embedded-opentype"), url("../fonts/hyundaisanstextoffice-bold-webfont.woff2") format("woff2"), url("../fonts/hyundaisanstextoffice-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Hyundai Light';
  src: url("../fonts/hyundai_sans_head_officelight.eot?ywsm632");
  src: url("../fonts/hyundai_sans_head_officelight.eot?ywsm632#iefix") format("embedded-opentype"), url("../fonts/hyundaisansheadoffice-light-webfont.woff2") format("woff2"), url("../fonts/hyundaisansheadoffice-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Mitsubishi Bold';
  src: url("../fonts/mmc-bold-webfont.woff2") format("woff2"), url("../fonts/mmc-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Mitsubishi Medium';
  src: url("../fonts/mmc-medium-webfont.woff2") format("woff2"), url("../fonts/mmc-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Mitsubishi Regular';
  src: url("../fonts/mmc-regular-webfont.woff2") format("woff2"), url("../fonts/mmc-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica Now Bold';
  src: url("../fonts/sterling/5761054/2a13627f-dba8-48e1-935c-a404f5293139.woff2") format("woff2"), url("../fonts/sterling/5761054/ce7a1fe7-2605-427a-b064-a01ebce69295.woff") format("woff"); }

@font-face {
  font-family: 'Helvetica Now Regular';
  src: url("../fonts/sterling/5761068/5eec4451-e90c-42e7-b4d8-9b8654a65568.woff2") format("woff2"), url("../fonts/sterling/5761068/f7cf763b-e79b-4c28-af92-803e0c3f16d4.woff") format("woff"); }

@font-face {
  font-family: 'Helvetica Now Medium';
  src: url("../fonts/sterling/5760871/2fd3ef81-07ed-45b4-a344-7a0defbdadcf.woff2") format("woff2"), url("../fonts/sterling/5760871/52492279-0374-4238-ba8b-751b50089cf0.woff") format("woff"); }

@font-face {
  font-family: 'Helvetica Now Light';
  src: url("../fonts/sterling/5760851/8448bbbb-ba62-45df-ae79-4ded34b0727b.woff2") format("woff2"), url("../fonts/sterling/5760851/ad48d569-0df5-4f5d-8ad3-8ce63dd84d7d.woff") format("woff"); }
