@font-face {
  font-family: "Hyundai Sans Head Regular";
  src: local("Hyundai Sans Head Regular"),
    url(../fonts/v5/hacc_sans_head_regular.woff) format("woff");
  src: local("Hyundai Sans Head Regular"),
    url(../fonts/v5/hacc_sans_head_regular.woff2) format("woff2");
}

@font-face {
  font-family: "Hyundai Sans Head Medium";
  src: local("Hyundai Sans Head Medium"),
    url(../fonts/v5/hacc_sans_head_medium.woff) format("woff");
  src: local("Hyundai Sans Head Medium"),
    url(../fonts/v5/hacc_sans_head_medium.woff2) format("woff2");
}

@font-face {
  font-family: "Hyundai Sans Text Regular";
  src: local("Hyundai Sans Text Regular"),
    url(../fonts/v5/hacc_sans_text_regular.woff) format("woff");
  src: local("Hyundai Sans Text Regular"),
    url(../fonts/v5/hacc_sans_text_regular.woff2) format("woff2");
}


